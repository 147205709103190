@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';

html {
  height: -webkit-fill-available;
}

body {
  font-family: $DEFAULT_FONT, $SECONDARY_FONT, $LABEL_FONT;
  font-weight: $REGULAR;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: #fcfcfc;
  overflow: auto !important;
  padding: 0 !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.secondary-font {
  font-family: $SECONDARY_FONT;
}

.label-button-font {
  font-family: $LABEL_FONT;
}

.full-height {
  height: 100%;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-12 {
  font-size: 12px;
}

.small-font-14 {
  font-size: 14px;
}
.small-font-15 {
  font-size: 15px;
}
.small-font-16 {
  font-size: 18px;
}

.elements-spacing {
  margin: 10px;
}

.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.MR-10 {
  margin-right: 10px;
}

.ML-10 {
  margin-left: 10px;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
}
.tirth-width {
  width: 30%;
}
.half-width {
  width: 50%;
}
.number-content {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
}
.elements-spacing-graph {
  margin-left: 10px;
  margin-right: 10px;
}
.legend-content {
  display: flex;
  flex-flow: row wrap;
}

.legend-line {
  margin-top: 7px;
  display: flex;
}

.outer-title {
  margin-top: 40px;
}

.MuiInputBase-root {
  font-size: 0.9rem !important;
}

.custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.noborder {
  box-shadow: none !important;
  margin-left: 15px;
  margin-right: 0px !important;
  margin-top: 15px !important;
  margin-bottom: 12px !important;
}
.flex {
  display: flex;
}
.first-card {
  height: 95% !important;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
}
.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}
.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}
.flex-container-start {
  @extend .flex;
  justify-content: flex-start;
}

.flex-row-3 {
  flex-basis: 30%;
}
.flex-1 {
  flex: 1;
}

.input-text {
  margin: 10px 5px 20px 5px !important;
  background-color: white;
}

.image-size {
  object-fit: scale-down;
  object-position: center center;
  max-width: 100%;
  max-height: 400px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font, .secondary-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font, .secondary-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font, .secondary-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font, .secondary-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font, .secondary-font;
  font-weight: $BOLD !important;
}

.black {
  @extend .default-font, .secondary-font;
  font-weight: $BLACK !important;
}

.unselected {
  color: $DISABLED !important;
}

.selected {
  color: $DEFAULT_TEXT !important;
}

.backdrop-color {
  background-color: $BACKGROUNDS;
}

.backdrop-transparent {
  background-color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 101 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $VERDE !important;
}

.interesse {
  @extend .color-white;
  background-color: $AZZURRO !important;
}

.proposta {
  @extend .color-white;
  background-color: $ARANCIO !important;
}

.compromesso {
  @extend .color-white;
  background-color: $ROSSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $VIOLA !important;
}

.opzionato {
  @extend .color-white;
  background-color: $GIALLO !important;
}

.search-button {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button;
}

.add-button {
  @extend .search-button;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}

.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.user-background {
  background-color: $USER_BACKGROUND !important;
}

.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  margin: 20px 15px 20px 0px;
  border-radius: 30px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0;
  }
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: $SELECT_BACKGROUND;
  color: $USER_DATA_COLOR;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.absolute-position-relative-content-loader {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.relative {
  position: relative;
}

.snackbar-body {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $COLOR_PRIMARY;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.snackbar-body-error {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $ERROR_COLOR;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  align-self: normal;
  margin-right: 5px;
}

.image-zoom-container {
  width: fit-content;
}

.image-transformcomponent-container {
  border: 2px solid black;
}

.zoom-button {
  background: $COLOR_PRIMARY;
  color: $COLOR_SECONDARY;
}

.ellipsis {
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.MuiSnackbarContent-message {
  color: white !important;
}

//SEZIONE MIA

.page-title {
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 600;
  color: #989898;
  margin-top: 80px;
  margin-bottom: 24px;
  margin-left: 16px;
}

.border-right-line {
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    border-right: 1px solid #e8e9eb;
  }
}

.padding-top-lg {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 50px;
  }
}

.table-cell-grid-bottom {
  height: 113px;
  border-bottom: 1px solid #e8e9eb;
}
.table-cell-grid-right {
  height: 113px;
  border-right: 1px solid #e8e9eb;
}

.placeholder-components {
  color: #989898;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  padding-top: 70px;
  padding-bottom: 10px;
}
.border-grid {
  @media (min-width: $BREAKPOINT_MD) {
    height: 377px;
  }
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.bottom-card {
  padding: 16px;
  @media (max-width: $BREAKPOINT_MD) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.anchor-link {
  color: #6bc1b4;
  text-decoration: none;
  border-bottom: 2px solid #6bc1b4;
}

.body-wrapper {
  display: flex;
  position: relative;
  transition: margin 1s;
  padding-top: 80px;
  height: calc(100vh - 80px);
  overflow: overlay;
}

.body-wrapper-open {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 216px;
  }
}
.body-wrapper-close {
  position: relative;
  transition: margin 1s;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: $DEFAULT_MARGIN;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-left: 72px;
  }
}

.title-padding {
  padding-bottom: 14px;
}

.grid-container-full-height {
  background: #ffffff;
  height: 100%;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  > * {
    display: flex;
    margin: 5px;
  }
}

.grid-container-with-sidebar {
  width: calc(100% - 110px) !important;
}

.typology-grid {
  display: block;
  font-size: 20px;
  > * {
    flex: auto;
  }
}

.MuiDialog-paper.login-modal {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
}

.login-modal-content {
  display: flex;
}

.MuiDialogTitle-root.login-modal-title {
  display: none;
}

.login-grid-container {
  color: white;
}

.login-grid-login {
  align-self: center;
  border-right: 1px solid white;
}

.login-grid-register {
  align-self: center;
}

.plan-grid-select {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > * {
    flex: auto;
  }
  height: 100%;
}

.plan-grid-details {
  display: flex !important;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  > .MuiPaper-root {
    flex-shrink: 0;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    overflow: auto;
    padding-top: 180px;
  }
}

.summary-grid-details {
  display: flex !important;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  > .MuiPaper-root {
    flex-shrink: 0;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    overflow: auto;
    padding-left: 1%;
    max-height: unset;
  }
}

.box-grid-details {
  display: flex !important;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  > .MuiPaper-root {
    flex-shrink: 0;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    overflow: auto;
    padding-top: 150px;
  }
}

.mobile-shadow {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: relative;
  }
}

.mobile-shadow-background {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.2;
    z-index: -1;
  }
}

.scrollarea .scrollbar-container {
  opacity: 1 !important;
  background: whitesmoke !important;
}

.scrollarea .scrollbar-container.vertical {
  width: unset;
  .scrollbar {
    background: #705466 !important;
    border-radius: 5px;
    width: 5px;
    margin-left: unset;
  }
}

.scrollarea .scrollbar-container.horizontal {
  height: unset;
  margin-bottom: 5px;
  .scrollbar {
    background: #705466 !important;
    border-radius: 5px;
    height: 5px;
    margin-left: unset;
  }
}

.selected-appartment-plan-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.selected-appartment-gallery-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 1px solid #707070;
}

.planimetria {
  border: 1px solid #f5942e;
  max-width: 100%;
  margin-top: 30px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: unset;
  }
}

.planimetria-step-posto-auto {
  border: 1px solid #707070;
  max-width: 100%;
}

.summary-footer-card {
  position: sticky;
  bottom: 0;
}

.footer-price.MuiCard-root {
  width: 100%;
  bottom: 0px;
  position: absolute;
  overflow: visible;
  background: none;
  z-index: 100;

  .card-price {
    background: #5e5259d6 0% 0% no-repeat padding-box !important;
    padding-inline: 25px !important;
    text-align: center;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding-inline: 20px !important;
      padding-top: 10px !important;
      opacity: 1 !important;
    }
  }
}

.MuiTypography-h3 {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 20px !important;
  }
}

.MuiTypography-body1 {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 10px !important;
    color: white;
  }
}

.MuiTypography-body2 {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 9px !important;
    color: white;
  }
}

.price-title-0 {
  color: #ffffff;
  font-size: 14px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 8px !important;
    opacity: 1 !important;
  }
}

.price-title-1 {
  color: #ffffff;
  font-size: 11px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 8px !important;
    opacity: 1 !important;
  }
}

.label-price {
  letter-spacing: 0.72px;
  font-size: 12px !important;
  color: #f8dec4 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 9px !important;
  }
}

.MuiButton-root.button-plan-next {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: unset !important;
  }
}

.summary-image-container {
  max-height: 100%;
  font-size: 18px;
  > * {
    padding-left: 80px;
    padding-top: 30px;
    flex: auto;
  }
}

.prospect-select {
  flex: none;
  margin-left: 80px;
  margin-top: 20px;
}

.mobile-prospect-svg-container {
  position: fixed;
  bottom: -10px;
  left: -43%;
  background-color: white;
  width: 180%;
  g[id^='_Seleziona_' i],
  g[id^='_Selezionato_' i],
  g[id^='_Venduto_' i] {
    display: none;
  }
}

.mobile-box-svg-container {
  position: fixed;
  bottom: -100px;
  left: -50%;
  background-color: white;
  width: 200%;
  max-height: calc(100vh + 100px);
  g[id^='_Seleziona-' i],
  g[id^='_Selezionato-' i],
  g[id^='_Venduto-' i] {
    display: none;
  }
}

.prospect-svg-container {
  overflow: hidden;
  background-color: white;
  > svg {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
  g[id^='_Seleziona_' i],
  g[id^='_Selezionato_' i],
  g[id^='_Venduto_' i] {
    display: none;
  }
  g[id^='_Seleziona_' i]:hover {
    opacity: 0.6;
  }
}

.box-svg-container {
  overflow: hidden;
  background-color: white;
  > svg {
    width: 100%;
    max-height: 100%;
    height: 100%;
  }
  g[id^='_Seleziona-' i],
  g[id^='_Selezionato-' i],
  g[id^='_Venduto-' i] {
    display: none;
  }
  g[id^='_Seleziona-' i]:hover {
    opacity: 0.6;
  }
}

.drawerOpenMobile {
  > .MuiDrawer-paper {
    background: rgba(0, 0, 0, 0);
  }
}

.drawerOpenMobileScelta {
  > .MuiDrawer-paper {
    background-color: rgba(0, 0, 0, 0);
  }
}

.drawerBottom {
  > .MuiDrawer-paper {
    visibility: visible !important;
    transform: translateY(477px) translateY(-477px) !important;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.drawerCloseBottom {
  > .MuiDrawer-paper {
    visibility: hidden !important;
  }
}

.drawerOpen {
  > .MuiDrawer-paper {
    top: 81px;
    width: 460px;
    background-color: white;
  }
  .MuiDrawer-paperAnchorRight {
    right: 110px !important;
  }
  .MuiDrawer-paperAnchorDockedRight {
    border-left: 3px solid orange !important;
  }
}

.drawerClose,
.drawerCloseColonna {
  > .MuiDrawer-paper {
    top: 81px;
    width: 110px;
    overflow-x: hidden;
    transform: translateX(0px) translateX(0px) !important;
  }
}

.drawerOpenColonna {
  > .MuiDrawer-paper {
    top: 81px;
    width: 110px;
    overflow-x: hidden;
    transform: translateX(0px) translateX(0px) !important;
    visibility: visible !important;
    border-left: 2px solid orange !important;
  }
}

.view-button,
.view-button-prospetto {
  color: orange;
  background: #f8f6f4 !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-left: 5px !important;
  padding: unset !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-bottom: unset !important;
  }
}

.view-button-prospetto {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: unset !important;
  }
}

.gallery-button {
  width: 100%;
  margin-top: 16px !important;
  margin-bottom: 30px !important;
  color: #f5942e;
  background: #f6e4cf !important;
  border: 2px solid #f5942e !important;
  justify-content: space-evenly !important;
  padding-inline: unset !important;
}

button.gallery-button:hover {
  color: darkorange !important;
}

button.view-button:hover {
  color: orange !important;
}

button.caratteristiche-right-icon:disabled {
  visibility: hidden;
}

sup {
  font-size: xx-small;
}

.zoom-container {
  position: relative;
  .icon-zoom,
  .icon-zoom-box,
  .icon-zoom-map,
  .icon-zoom-summary {
    position: absolute !important;
    top: 380px;
    left: 350px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      top: 10px !important;
      left: 10px;
    }
    img {
      height: 24px;
      width: 24px;
    }
    .MuiIconButton-label {
      width: unset;
    }
  }
  .icon-zoom-box {
    top: 350px;
  }
  .icon-zoom-map {
    top: -55px;
    left: 260px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      top: -55px !important;
      left: 245px;
    }
  }
  .icon-zoom-summary {
    top: 20px;
    left: 20px;
    z-index: 1;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      left: 10px !important;
    }
  }
}

.vista-container {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  max-width: 960px;
  min-height: 500px;
  height: 50vh !important;
  border: 1px solid lightgray;
  overflow: auto;
  position: relative;
  > div:first-child {
    z-index: 5;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    height: 211px !important;
    min-height: unset;
  }
  @media (max-width: $BREAKPOINT_LG) {
    max-width: unset;
  }
}

.summary-container-details {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 25px;
  max-width: 686px;
  border: 1px solid #9f9e9d;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: unset;
    margin-left: unset;
    margin-right: unset;
    max-width: unset;
    max-height: unset;
    border: unset;
  }
}

.container-with-drawer {
  width: calc(100% - 102px);
}

.summary-details > .MuiCardHeader-avatar {
  margin-bottom: 15px;
}

.summary-details {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-left: 8px !important;
    padding-bottom: unset !important;
  }
  @media (max-width: 1400px) {
    flex-direction: column;
  }
}

.hidden-divider {
  @media (max-width: 1400px) {
    display: none;
  }
}

.popup {
  max-width: 400px;
}

.area {
  max-height: 100%;
  background: white;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    box-shadow: 0px -5px 10px darkgrey;
  }
}

.drawer .scrollarea {
  &.area .scrollarea-content {
    padding-bottom: 150px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding-bottom: 110px;
    }
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.scrollarea .scrollbar-container {
  z-index: 0 !important;
}

.button-typology {
  width: 136px;
  height: 32px;
  font-family: 'Raleway' !important;
  font-size: 12px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 90px;
    height: 25px;
    font-size: 8px !important;
  }
}

.info:hover {
  opacity: 0.5;
}

.icon-popup {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.bagno-mobile {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-right: 8px !important;
  }
}

.label-bagno {
  color: #1c1c1c !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 10px !important;
  }
}

.gallery-button-divider {
  width: 2px !important;
  background-color: #f5942e !important;
}

.gallery-button-text {
  letter-spacing: 0.74px;
  color: #f5942e !important;
  font-size: 13px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 10px !important;
  }
}

.titolo-caratteristiche {
  margin-left: 25px !important;
  margin-block: 35px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-left: 16px !important;
    margin-block: unset !important;
    padding-block: 17px;
  }
}

.appartment-button {
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    margin-right: 45px !important;
  }
}

.card-prospetto {
  background: #f8f6f4;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-right: 40px;
}

.title-typology-mobile {
  padding-top: 25px;
  padding-left: 18px;
}

.card-tipologia {
  background: #f8f6f4;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding-top: 25;
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: unset;
  }
}

.info-app {
  font-size: 11px;
  padding-bottom: 17px;
  color: #9f9e9d;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 10px !important;
  }
}

.label-posto-auto,
.sublabel-posto-auto {
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.label-posto-auto {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 10px !important;
  }
}

.sublabel-posto-auto {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 12px !important;
  }
}

.summary-title {
  margin-left: 25px !important;
  margin-block: 35px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-left: 25px !important;
    margin-block: 20px !important;
    font-size: 15px !important;
  }
}

.summary-card-header {
  max-height: 54px;
  border: 1px solid #9f9e9d;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-left: 8px !important;
    padding-bottom: unset !important;
  }
}

.image-dialog {
  .MuiDialog-paperScrollPaper {
    height: 80%;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      height: calc(100vw - 10px);
      margin-inline: 10px;
    }
  }
  .MuiDialogContent-root {
    padding-bottom: 40px;
    padding-inline: 40px;
    padding-top: unset;
    height: 100%;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding-bottom: 20px;
      padding-inline: 20px;
    }
  }
}

.carousel-full-height {
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.full-size-contain {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.fit-contain {
  object-fit: contain !important;
}

#pulsa {
  animation: pulse 1s alternate infinite;
}

@keyframes pulse {
  100% {
    color: #f5942e;
  }
}

.icon-promo {
  position: absolute;
  right: 5%;
  top: -15px;
  height: 76px;
  width: 102px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: -10px;
    width: 70px;
  }
}

#show {
  animation: show 2s;
}

@keyframes show {
  0% {
    margin-bottom: 15px;
  }
  100% {
    margin-bottom: unset;
  }
}

.prezzo-box-auto {
  .prezzo-box {
    position: relative;
    .barra-prezzo-box {
      width: 100%;
      border-bottom: 4px solid #f5942e;
      transform: translateY(-25px) translateX(0px) rotate(-8deg);
      position: absolute;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 90px;
        border-bottom: 2px solid #f5942e;
        transform: translateY(-15px) translateX(20%) rotate(-8deg);
      }
    }
  }
}

.prezzo-box-no-auto {
  position: relative;
  .prezzo-box {
    .barra-prezzo-box {
      width: 38%;
      border-bottom: 4px solid #f5942e;
      transform: translateY(-25px) translateX(85%) rotate(-8deg);
      position: absolute;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        width: 90px;
        border-bottom: 2px solid #f5942e;
        transform: translateY(-15px) translateX(-50%) rotate(-8deg);
        left: 50%;
      }
    }
  }
}

.barra-prezzo-plan {
  width: 38%;
  border-bottom: 4px solid #f5942e;
  transform: translateY(-25px) translateX(115px) rotate(-8deg);
  position: absolute;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 25%;
    border-bottom: 2px solid #f5942e;
    transform: translateY(-15px) translateX(-10px) rotate(-8deg);
  }
}

.barra-prezzo-summary {
  width: 100%;
  right: 0;
  bottom: 50%;
  transform: rotate(-8deg) translateY(50%);
  position: absolute;
  border-bottom: 3px solid #f5942e;
}

#price {
  animation: price 1s;
}

@keyframes price {
  0% {
    width: 0;
  }
  25% {
    width: 10%;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      width: 7%;
    }
  }
  50% {
    width: 20%;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      width: 14%;
    }
  }
  75% {
    width: 30%;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      width: 21%;
    }
  }
}

.price-posto-auto {
  font-size: 17px;
  color: #ffffff;
  font-weight: 400;
}

.label-promo {
  font-size: 10px !important;
  opacity: 0.7;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 8px !important;
  }
}

.prezzo {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #ffffff;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: 16px !important;
    font-weight: normal !important;
  }
}

.MuiStepButton-root {
  width: 65px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 25px !important;
  }
}

.icon-promo-summary {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: -19px;
  height: 76px;
  width: 102px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    right: 0;
  }
}

.modal-image-disclaimer {
  bottom: 0px !important;
  min-width: initial !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0px 5px !important;
    left: 0px !important;
  }
}

.summary-image-disclaimer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  z-index: 6 !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    justify-content: center;
  }
}

.summary-image-disclaimer {
  position: relative !important;
  min-width: initial !important;
  max-width: fit-content;
  text-align: center;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin: 0px 5px !important;
  }
}

.password-min-characters {
  font-size: 10px;
  text-align: center;
  font-style: italic;
  letter-spacing: 0.23px;
  color: #705466;
}

.close-icon-container {
  position: fixed;
  top: 260px;
  right: 0;
  z-index: 10;
  text-align-last: end;
  margin-right: 8px;
  z-index: 10;
  svg {
    background-color: white;
    border-radius: 30px;
  }
}

.close-icon-container-box {
  position: fixed;
  top: 230px;
  right: 0;
  z-index: 10;
  text-align-last: end;
  margin-right: 8px;
  z-index: 10;
  svg {
    background-color: white;
    border-radius: 30px;
  }
}

.private-area-button .MuiTypography-subtitle2 {
  color: #cd942e;
}

.start-icon-mobile {
  padding-bottom: 2px;
  width: 30px;
  height: 30px;
}

.icona-modifica-riepilogo {
  padding-right: unset !important;
  justify-content: flex-end !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    position: absolute !important;
    right: 37px !important;
  }
}

.scroll-orizzontale-posti-auto {
  width: 100%;
  > div {
    width: fit-content;
  }
}

.divider-cta-mobile {
  width: 1px !important;
  background-color: #705466 !important;
  margin-block: 30px !important;
  margin-inline: 10px !important;
  @media (max-width: 300px) {
    margin-inline: unset !important;
  }
}

.scroll-orizzontale-riepilogo > div {
  width: 100%;
  @media (max-width: $BREAKPOINT_MD) {
    width: 735px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 530px;
  }
}

.title-card-mobile {
  color: #1c1c1c !important;
  font-size: 10px !important;
  font-weight: 600 !important;
}

.subtitle-card-mobile {
  color: #1c1c1c !important;
  font-size: 12px !important;
}

.avatar-card-mobile {
  width: 40px;
  height: 40px;
}

.summary-select-grid-item {
  > div {
    @media (max-width: $BREAKPOINT_LG) {
      margin: 30px 30px 10px 30px !important;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin: unset !important;
    }
  }
}

.summary-details-grid-item {
  > div {
    @media (max-width: $BREAKPOINT_LG) {
      margin: 30px auto 10px auto !important;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      margin: unset !important;
    }
  }
}

.dialog-content-question {
  margin-top: 20px !important;
  font-weight: 700 !important;
  color: #481635 !important;
}

.MuiDialogContent-root {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-inline: unset !important;
  }
}

.label-affaccio {
  font-style: italic;
  margin-top: 10px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-top: unset;
    font-size: 10px;
  }
}

.summary-box-container {
  display: flex;
  border: 1px solid rgb(159, 158, 157);
  margin: 10px 25px;
  padding-left: 16px;
  justify-content: space-between;
  max-height: 88px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-inline: 16px;
    padding-left: 10px;
  }
  .icon-no-box {
    width: 45px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      width: unset;
    }
  }
  > img {
    margin-right: 15px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      height: 40px;
      align-self: center;
    }
  }
  .summary-box-title {
    align-self: center;
    margin-right: auto;
    color: #1c1c1c;
    font-size: 15px;
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      font-size: 12px;
    }
    .summary-box-title-1 {
      font-size: 12px;
      margin-bottom: 5px;
      font-weight: 600;
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        font-size: 10px;
      }
    }
  }
  .summary-icon-button {
    margin-right: 8px;
    > img {
      width: 30px;
    }
  }
}

div.CarouselItem ~ div {
  @media only screen and (hover: none) {
  }
  &:active {
    > button {
      opacity: 0.6 !important;
    }
  }
  &:hover {
    > button {
      opacity: unset !important;
      filter: none !important;
    }
  }
}

.signin-vendor {
  .signin-vendor-confirm-container {
    @media (max-width: $BREAKPOINT_LG) {
      width: calc((100% / 2) - 150px);
      padding: 20px;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: unset;
      border-radius: unset;
      box-shadow: none !important;
      position: absolute;
      max-width: 100%;
    }
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding-inline: 30px;
    padding-block: 25px;
    max-width: fit-content;
    > * {
      width: 100%;
    }
    > :not(img):not(.MuiSnackbar-root) {
      background-color: white;
      z-index: 1;
      position: relative;
    }
  }
  & ~ div {
    display: none;
  }
}

.salta-la-coda-container {
  display: flex;
  place-content: center;
  flex-wrap: wrap;
  .salta-la-coda-icon {
    width: 29px;
    height: 24px;
    margin-right: 24px;
  }
}

.salta-la-coda-modal-paper.salta-la-coda-modal-paper {
  width: 80vw;
  max-width: 1150px;
  background-color: transparent;
  box-shadow: none;
  height: 90%;
  max-height: 90%;
  padding-bottom: 5%;
  padding-top: 5%;
  justify-content: center;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
    margin-inline: 0px;
    padding-inline: 0px;
  }
  .scrollarea.area {
    background: transparent;
    @media (max-width: $BREAKPOINT_MD) {
      max-width: 500px;
    }
  }
  .salta-la-coda-modal-grid-container {
    height: fit-content;
    align-items: center;

    .salta-la-coda-modal-image-panel {
      width: 45vw;
      height: 60vh;
      min-height: 500px;
      min-width: 500px;
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      @media (max-width: $BREAKPOINT_MD) {
        width: 100%;
        max-width: 500px;
        min-width: unset;
        height: 350px;
        min-height: unset;
      }
      @media (max-width: $BREAKPOINT_SMARTPHONE) {
        height: 240px;
      }
      .salta-la-coda-modal-image-panel-text-container {
        height: 350px;
        width: 350px;
        border-radius: 200px;
        background-color: rgba(28, 28, 28, 0.85);
        position: relative;
        color: white;
        align-items: center;
        @media (max-width: $BREAKPOINT_MD) {
          align-content: space-evenly;
          padding-inline: 50px;
        }
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          border-radius: 0px;
          padding-inline: 10px;
          width: 180px;
          height: 210px;
        }
        .salta-la-coda-modal-image-panel-text-discover {
          padding-right: 20px;
          font-size: 30px;
          line-height: 35px;
          font-style: italic;
          font-weight: 300;
          text-align: right;
          @media (max-width: $BREAKPOINT_MD) {
            text-align: center;
            padding: 0 30px;
          }
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .salta-la-coda-modal-image-panel-text-theunits {
          img {
            width: 120px;
            height: 120px;
            @media (max-width: $BREAKPOINT_SMARTPHONE) {
              height: 70px;
              width: auto;
            }
          }
        }
        .salta-la-coda-modal-image-panel-text-from-home {
          position: absolute;
          bottom: 60px;
          font-weight: 700;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 14px;
          @media (max-width: $BREAKPOINT_MD) {
            position: relative;
            bottom: 0px;
          }
          @media (max-width: $BREAKPOINT_SMARTPHONE) {
            font-size: 10px;
          }
        }
      }
    }
    .salta-la-coda-modal-text-panel {
      background: #f3f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35vw;
      height: auto;
      min-width: 350px;
      padding-block: 25px;
      min-height: 100%;
      @media (max-width: $BREAKPOINT_MD) {
        width: 100%;
        max-width: 500px;
        min-width: unset;
      }
      > * {
        width: 85%;
        @media (max-width: $BREAKPOINT_SMARTPHONE) {
          width: 90%;
        }
      }
      .salta-la-coda-modal-text-top-icon {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        background-color: #331226;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }
      .salta-la-coda-modal-text-title {
        color: #000;
        font-size: 17px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-top: 0px;
        margin-bottom: 10px;
      }
      .salta-la-coda-modal-text-explain-container {
        padding-block: 10px;
        .salta-la-coda-modal-text-explain {
          display: inline-flex;
          align-items: center;
          width: 100%;
          .salta-la-coda-modal-text-explain-top-left {
            width: 59px;
            border-right: 1px solid #331226;
            margin-left: 10px;
            padding-right: 10px;
            padding-block: 15px;
            .salta-la-coda-modal-text-explain-icon {
              height: 50px;
              width: 50px;
              border-radius: 60px;
              background-color: #331226;
              display: flex;
              align-items: center;
              justify-content: center;
              @media (max-width: $BREAKPOINT_SMARTPHONE) {
                height: 30px;
                width: 30px;
              }
            }
            @media (max-width: $BREAKPOINT_SMARTPHONE) {
              width: 30px;
              border: 0;
              padding-right: 0;
            }
          }
          .salta-la-coda-modal-text-explain-bottom-left {
            width: 60px;
            margin-inline: 10px;

            @media (max-width: $BREAKPOINT_SMARTPHONE) {
              width: 30px;
              border: 0;
              margin-right: 0;
            }
          }
          .salta-la-coda-modal-text-explain-top-right {
            width: calc(100% - 100px - 50px);
            font-size: 11px;
            line-height: 17px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 5px;
            padding-inline: 20px;
            text-align: left;
            @media (max-width: $BREAKPOINT_SMARTPHONE) {
              width: calc(100% - 30px - 30px);
              padding-inline: 10px;
            }
          }
          .salta-la-coda-modal-text-explain-bottom-right {
            width: calc(100% - 80px - 50px);
            font-size: 11px;
            line-height: 18px;
            margin: 5px;
            padding-inline: 20px;
            text-align: left;
            @media (max-width: $BREAKPOINT_SMARTPHONE) {
              width: calc(100% - 30px - 30px);
              padding-inline: 10px;
            }
            span {
              font-weight: 700;
              text-transform: uppercase;
            }
          }
        }
      }
      .salta-la-coda-modal-link-button {
        border: 2px solid #816f7a;
        height: 30px;
        background: #f5942e;
        margin-top: 8px;
        height: fit-content;
      }
    }
  }
}
