@import 'styles/variables.scss';
@import 'styles/colors.scss';

.modal-text {
  text-align: center;
}

.modal-title {
  @extend .modal-text;
  font-size: 28px;
}

.modal-content {
  @extend .modal-text;
  padding-bottom: 20px !important;
}

.modal-actions {
  justify-content: center !important;
  padding-bottom: 20px !important;
}
