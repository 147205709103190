@import 'styles/colors';
@import 'styles/variables';

.header-stepper,
.header-mobile {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  top: 30px;
  left: 0;
  height: 50px;
  background: white;
  z-index: 4;
  flex-flow: row wrap;
  border-bottom: 1px solid #705466;
  .logo-header-stepper {
    float: left;
    width: 35px;
    height: 50px;
    margin-left: 20px;
  }
}

.header-mobile {
  flex-flow: unset;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 30px;
  background: #efefef;
  z-index: 4;
  flex-flow: row wrap;
  align-content: center;
}
.tecma-elements {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9f3f1;
  border-right: 1px solid #d4c8c5;
  .image-tecma-wrapper {
    background-color: #d4c8c5;
    display: flex;
    height: 100%;
    width: 56px;
    border-right: 1px solid #d4c8c5;
    .image-tecma {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }
  .tuning-wrapper {
    display: flex;
    font-size: 20px;
    color: black;
    //width: 200px;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 50px;
  }
}
.desktop-elements {
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  background-color: #f9f3f1;
  .language-selector {
    height: 50px;
    padding: 0px !important;
    border-left: 1px solid white !important;
    margin-top: auto;
    margin-bottom: auto;
    color: white !important;
    border-radius: 0px !important;
    min-width: 51px;
    font-size: 14px;
  }
}

.card-bread {
  font-size: $BIG_FONT;
  color: $USER_BACKGROUND;
}

.card-title {
  color: white;
  font-size: $BIG_FONT !important;
  font-weight: $SEMI_BOLD;
}

.lang-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -50px;
    right: 0px;
    margin-left: 10px;
  }
}

.mobile-drawer {
  color: white !important;
  border-radius: 0px !important;
  border-left: 1px solid white !important;
  height: 100%;
}

.no-border {
  border: 0px !important;
}
.corner-positioning {
  right: 0;
  left: unset !important;
}

.header-divider {
  float: left;
  height: 75% !important;
  background-color: #705466 !important;
  margin-inline: 25px !important;
  margin-top: 5px !important;
}
