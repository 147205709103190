@import 'styles/colors';
@import 'styles/variables';

.card-wrapper {
  padding-inline: 25px;
  margin-bottom: 25px;
  height: 100%;
  background-color: $CARD_BACKGROUND;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-inline: 16px;
  }
}

.card-summary-wrapper {
  margin-inline: 25px;
  margin-bottom: 25px;
  height: 100%;
  background: #5e5259 !important;
  border: 1px solid #9f9e9d;
  padding: 25px;
  padding-top: 15px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-inline: 15px;
  }
}
