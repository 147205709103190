@font-face {
  font-family: Sagoe;
  src: url('./fonts/Sagoe/SegoeUI.ttf') format('truetype');
}

@font-face {
  font-family: SagoeItalic;
  font-style: italic;
  src: url('fonts/Sagoe/SegoeUIItalic.ttf') format('truetype');
}

@font-face {
  font-family: SagoeBold;
  font-style: bold;
  src: url('fonts/Sagoe/SegoeUIBold.ttf') format('truetype');
}

@font-face {
  font-family: Sagoe;
  font-style: light;
  src: url('fonts/Sagoe/SegoeUILight.ttf') format('truetype');
}

@font-face {
  font-family: SagoeBoldItalic;
  font-style: bold italic;
  src: url('fonts/Sagoe/SegoeUIBoldItalic.ttf') format('truetype');
}
