@import 'styles/colors.scss';
@import 'styles/variables.scss';

.right-half-login {
  display: flex;
  position: absolute;
  right: 0px;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  min-height: 600px;
  height: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
  }
}

.login-margin-bottom {
  margin-bottom: 15px;
}

.login-icon {
  @extend .login-margin-bottom;
  width: $LOGO_WIDTH_HUGE;
}

.login-button {
  color: white !important;
  border: 3px solid #f5942e !important;
}

.login-logo-vendor {
  width: $LOGO_WIDTH_HUGE;
  position: absolute;
  bottom: 10px;
}

.login-title {
  color: black;
  letter-spacing: 3px;
  font-size: 28px;
}

.login-hint {
  color: white;
  font-size: 18px;
}

.left-half-login {
  width: 50%;
  position: absolute;
  left: 0px;
  object-fit: cover;
  height: 100%;
  border-right: 1px solid black;
}

.input-home {
  width: 100px;
}

#login,
#signin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-mobile-svg {
  overflow: hidden;
  width: 100%;
  position: fixed;
  bottom: -12%;
  z-index: 0;
  > svg {
    width: 100%;
    height: 100%;
    g[id^='_Seleziona_' i],
    g[id^='_Selezionato_' i],
    g[id^='_Venduto_' i] {
      display: none;
    }
  }
}

.login-background-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-background-svg-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  > svg {
    width: 100%;
    height: 100%;
    g[id^='_Seleziona_' i],
    g[id^='_Selezionato_' i],
    g[id^='_Venduto_' i] {
      display: none;
    }
  }
}

.signin-container {
  @media (max-width: $BREAKPOINT_LG) {
    width: 80%;
    padding: 10px 30px;
    margin-top: unset;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    border-radius: unset;
    box-shadow: none !important;
  }
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 60px;
  max-width: 950px;
  padding-inline: 90px;
  padding-top: 70px;
  > * {
    width: 100%;
  }
}

.grid-padding-top {
  padding-top: 13px;
  @media (max-width: $BREAKPOINT_LG) {
    padding-top: unset;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-top: 10px;
  }
}

.confirm-container {
  @media (max-width: $BREAKPOINT_LG) {
    top: 82px;
    width: calc((100% / 2) - 150px);
    padding: 50px;
    padding-block: 20px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: 80px;
    width: 100%;
    padding: unset;
    border-radius: unset;
    box-shadow: none !important;
    position: absolute;
    max-width: 100%;
  }
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-block: 70px;
  padding-inline: 110px;
  max-width: fit-content;
  > * {
    width: 100%;
  }
}

.login-container {
  @media (max-width: $BREAKPOINT_LG) {
    width: calc((100% / 2) - 150px);
    padding: 50px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: 80px;
    width: 100%;
    padding: unset;
    border-radius: unset;
    box-shadow: none !important;
    position: absolute;
    max-width: 100%;
  }
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-inline: 110px;
  padding-block: 90px;
  max-width: fit-content;
  > * {
    width: 100%;
  }
  > :not(img):not(.MuiSnackbar-root) {
    background-color: white;
    z-index: 1;
    position: relative;
  }
}

.password-update-container {
  @media (max-width: $BREAKPOINT_LG) {
    padding: 50px;
    top: 150px;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: 80px;
    width: 100%;
    padding: unset;
    border-radius: unset;
    box-shadow: none !important;
  }
  top: 340px;
  padding-inline: 115px;
  padding-block: 60px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: absolute;

  > * {
    width: 100%;
  }
}

.signin-checkbox {
  height: auto !important;
  padding-top: unset !important;
}

#login-form {
  margin-top: 53px;
  z-index: 1;
  @media (max-width: $BREAKPOINT_LG) {
    margin-top: 3%;
  }
  > * {
    width: 100%;
    height: 42px;
    justify-content: center;
  }
  input:not([type='checkbox']) {
    color: #9f9e9d;
    font-size: 10px;
  }
}

#new-password-form {
  margin-top: 20px;
  @media (max-width: $BREAKPOINT_LG) {
    margin-top: 3%;
  }
  > * {
    width: 100%;
    height: 42px;
    justify-content: center;
  }
  input:not([type='checkbox']) {
    color: #9f9e9d;
    font-size: 10px;
  }
}

#signin-form {
  margin-top: 57px;
  @media (max-width: $BREAKPOINT_LG) {
    margin-top: unset;
  }
  > * {
    width: 100%;
    justify-content: center;
  }
  input:not([type='checkbox']) {
    color: #9f9e9d;
    font-size: 10px;
  }
  input#firstname-signin,
  input#lastname-signin,
  input#email-signin,
  input#tel-signin {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
}

.password-input {
  background-color: rgb(248, 246, 244);
  border-bottom: 3px solid #f5942e !important;
  margin-bottom: 7px !important;
  fieldset {
    border: none !important;
  }
  :hover {
    background-color: ghostwhite;
  }
}

.login-input,
.signin-input {
  background-color: rgb(248, 246, 244);
  border-bottom: 3px solid #f5942e !important;
  margin-bottom: 25px !important;
  fieldset {
    border: none !important;
  }
  :hover {
    background-color: ghostwhite;
  }
}

.signin-input {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    margin-right: unset !important;
  }
  margin-bottom: unset !important;
}

.wait-for-mail-container {
  @media (max-width: $BREAKPOINT_LG) {
    top: calc(18% + 280px);
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: calc(23% + 210px);
    background: #f5942e !important;
  }
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 650px;
  text-align: center;
  background: #f8f6f4 !important;
}

.linea-tratteggiata-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.linea-tratteggiata {
  position: absolute;
  top: calc(21% + 222px);
  right: calc(50% + 74px);
  z-index: 1;
  @media (max-width: $BREAKPOINT_LG) {
    width: 600px;
    top: calc(18% + 145px);
    right: calc(50% + 44px);
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    top: calc(23% + 122px);
    right: calc(50% + 48px);
  }
}

.cerchio-bianco {
  position: absolute !important;
  top: 21% !important;
  width: 376px !important;
  height: 376px !important;
  background-color: white !important;
  color: white !important;
  z-index: 0;
  @media (max-width: $BREAKPOINT_LG) {
    top: 18% !important;
    width: 250px !important;
    height: 250px !important;
  }
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 200px !important;
    height: 200px !important;
    top: 23% !important;
  }
}

.icona-lettera {
  @media (max-width: $BREAKPOINT_LG) {
    width: 140px;
  }
}

.send-email-container {
  position: fixed;
  height: 100%;
  width: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    background: #f5942e !important;
  }
}

.sx-background,
.dx-background {
  opacity: 0.1;
  height: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.sx-background {
  @media (max-width: $BREAKPOINT_LG) {
    height: 520px;
    position: fixed;
    bottom: 0;
  }
}

.dx-background {
  @media (max-width: $BREAKPOINT_LG) {
    height: 710px;
  }
}

.title-send-email {
  font-weight: bold;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: small !important;
    margin-top: 10% !important;
  }
}

.subtitle-send-email {
  margin-block: 40px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    font-size: small !important;
    margin-top: 20px !important;
  }
}

.error-snackbar {
  @media (min-width: $BREAKPOINT_SMARTPHONE) {
    max-width: 30%;
    min-width: 300px;
  }
}

.visibility-icon-button {
  color: #9f9e9d !important;
  .MuiIconButton-label {
    display: flex;
  }
}
